var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from 'react';
import { FileInput, FileField, ArrayField, Datagrid, TextField, useTranslate } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import ActionDeleteIcon from '@material-ui/icons/Delete';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { red } from '@material-ui/core/colors';
import { useDelete, useRefresh, useNotify, useRedirect } from 'ra-core';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent'
                }
            }
        },
        FileInput: {
            width: 544,
            clear: 'left',
            paddingTop: '12px !important'
        },
        clearLeft: {
            clear: 'left'
        },
        anchorTag: {
            color: 'inherit',
            textDecoration: 'none'
        },
        uploadedMedia: {
            color: 'rgba(0, 0, 0, 0.54)',
            padding: 0,
            fontSize: '0.75rem',
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            lineHeight: '1',
            margin: '16px 0 10px',
            paddingLeft: 12
        },
        buttonProgress: {
            color: red[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        }
    });
});
var options = {
    undoable: true,
    onSuccess: {
        notification: {
            body: 'resources.Media.displayMediaFieldMessages.notification.deleteMedia.success',
            level: 'info'
        },
        refesh: true
    },
    onError: {
        notification: {
            body: 'resources.Media.displayMediaFieldMessages.notification.deleteMedia.error',
            level: 'warning'
        }
    }
};
var DeleteButton = function (_a) {
    var record = _a.record;
    var translate = useTranslate();
    var classes = useStyles();
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var notify = useNotify();
    var redirectTo = useRedirect();
    var refresh = useRefresh();
    var payload = { id: record.id };
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
    };
    var handleLoading = function () {
        setLoading(true);
    };
    var deleteOne = useDelete('Media', record.id, record, {
        undoable: false,
        onSuccess: function () {
            notify('resources.Media.displayMediaFieldMessages.notification.deleteMedia.success', 'info', {}, true);
            refresh();
        },
        onFailure: function () {
            notify('resources.Media.displayMediaFieldMessages.notification.deleteMedia.error', 'warning');
        }
    })[0];
    return (_jsxs("div", { children: [_jsxs(Button, __assign({ color: "primary", className: classes.deleteButton, onClick: handleClickOpen, disabled: loading, variant: loading ? 'contained' : 'text' }, { children: [loading && (_jsx(CircularProgress, { size: 24, className: classes.buttonProgress }, void 0)), _jsx(ActionDeleteIcon, {}, void 0), ' ', translate('resources.Media.displayMediaFieldMessages.button.delete')] }), void 0), _jsxs(Dialog, __assign({ open: open, onClose: handleClose, "aria-labelledby": "responsive-dialog-title" }, { children: [_jsx(DialogTitle, __assign({ id: "responsive-dialog-title" }, { children: translate('resources.Media.displayMediaFieldMessages.AlertBox.title') }), void 0), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: translate('resources.Media.displayMediaFieldMessages.AlertBox.bodyContent') }, void 0) }, void 0), _jsxs(DialogActions, { children: [_jsxs(Button, __assign({ onClick: handleClose }, { children: [_jsx(AlertError, { style: { paddingRight: '5px', fontSize: '20px' } }, void 0), translate('resources.Media.displayMediaFieldMessages.button.cancel')] }), void 0), _jsxs(Button, __assign({ onClick: function () {
                                    handleClose();
                                    handleLoading();
                                    deleteOne();
                                }, disabled: loading, color: "primary" }, { children: [_jsx(ActionCheck, { style: { paddingRight: '5px', fontSize: '20px' } }, void 0), translate('resources.Media.displayMediaFieldMessages.button.confirm')] }), void 0)] }, void 0)] }), void 0)] }, void 0));
};
var FileName = function (_a) {
    var record = _a.record;
    var translate = useTranslate();
    var downloadUrl = record.downloadUrl + "?token=" + localStorage.getItem('jwt_token');
    return (_jsx("span", { children: _jsx("a", __assign({ href: downloadUrl, download: true, title: translate('resources.Media.titles.clickToDownloadFile') }, { children: _jsx("span", { children: record.fileName }, void 0) }), void 0) }, void 0));
};
var DisplayMediaFields = function (_a) {
    var record = _a.record, _b = _a.sourceFieldName, sourceFieldName = _b === void 0 ? 'files' : _b, _c = _a.fileInputLabel, fileInputLabel = _c === void 0 ? 'resources.Media.displayMediaFieldMessages.uploadMedia' : _c, _d = _a.acceptFileType, acceptFileType = _d === void 0 ? 'application/pdf' : _d, _e = _a.acceptMultiple, acceptMultiple = _e === void 0 ? false : _e, _f = _a.showFileInput, showFileInput = _f === void 0 ? true : _f, _g = _a.showFilesList, showFilesList = _g === void 0 ? true : _g, _h = _a.viewFilesLabel, viewFilesLabel = _h === void 0 ? 'resources.Media.displayMediaFieldMessages.uploadedMedia' : _h, _j = _a.viewFileName, viewFileName = _j === void 0 ? 'media' : _j, _k = _a.allowDelete, allowDelete = _k === void 0 ? true : _k, rest = __rest(_a, ["record", "sourceFieldName", "fileInputLabel", "acceptFileType", "acceptMultiple", "showFileInput", "showFilesList", "viewFilesLabel", "viewFileName", "allowDelete"]);
    var translate = useTranslate();
    var classes = useStyles();
    var DisplayFileList = function () {
        if (record && record[viewFileName] && record[viewFileName].length > 0) {
            return (_jsxs(Fragment, { children: [_jsx("div", __assign({ className: classes.uploadedMedia }, { children: translate(viewFilesLabel) }), void 0), _jsx(ArrayField, __assign({ source: viewFileName, record: record, label: "" }, { children: _jsxs(Datagrid, __assign({ selectedIds: [] }, { children: [_jsx(FileName, { label: "resources.Media.fields.fileName" }, void 0), _jsx(TextField, { source: "fileSizeHuman", label: "resources.Media.fields.fileSize", sortable: false }, void 0), _jsx(TextField, { source: "createdAtHuman", label: "resources.Media.fields.createdAt" }, void 0), allowDelete && _jsx(DeleteButton, { undoable: false }, void 0)] }), void 0) }), void 0)] }, void 0));
        }
        return null;
    };
    return (_jsxs("div", __assign({ className: classes.clearLeft }, { children: [showFileInput && (_jsx(FileInput, __assign({ source: sourceFieldName, label: fileInputLabel, accept: acceptFileType, className: classes.FileInput, multiple: acceptMultiple }, rest, { children: _jsx(FileField, { source: "src", title: "title" }, void 0) }), void 0)), showFilesList && _jsx(DisplayFileList, {}, void 0)] }), void 0));
};
export default DisplayMediaFields;
