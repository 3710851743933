import {
  SimpleShowLayout,
  TextField,
  useTranslate,
  BooleanField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DisplayMediaFields } from "@bedrock/ra-core";
import { defaultShow } from "../../../../styles";
import VehicleFASTagDetails from "./VehicleFASTagDetails";
import { CommonMasterFields, SectionTitle, MESSAGE_NA } from "../../../..";
import { VehicleFasTagInputDetailsTable } from "./VehicleFasTagInputDetailsTable";

const useStyles = makeStyles(defaultShow);

const FastTagShowFormDataComponent = ({ isFront, ...rest }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const vehicleDetails = rest?.record?.data?.vehicles?.list;
  const vehicleFeeDetails = rest?.record?.data?.vehicles?.fees;

  return (
    <SimpleShowLayout {...rest}>
      <Typography className={classes.heading}>
        {translate("resources.FasTagEntries.fields.data.heading")}
      </Typography>

      <CommonMasterFields isFront={isFront}>
        <Typography className={classes.heading}>
          {translate(
            "resources.FasTagEntries.fields.data.vehicleFastTagDetails"
          )}
        </Typography>

        <VehicleFasTagInputDetailsTable
          vehicleDetails={vehicleDetails}
          vehicleFeeDetails={vehicleFeeDetails}
          isShow={true}
        // props={props}
        />
        {!isFront && (
          <SectionTitle label="resources.FasTagEntries.sections.administrative" />
        )}
        {!isFront && (
          <TextField
            source="data.bo.totalAmountReceived"
            label="resources.FasTagEntries.fields.data.totalAmountReceived"
            emptyText={MESSAGE_NA}
            className={classes.inlineGridWith50PercentWidth}
          />
        )}
        {!isFront && (
          <TextField
            source="data.bo.cashCheckNumber"
            label="resources.FasTagEntries.fields.data.cashCheck"
            emptyText={MESSAGE_NA}
            className={classes.inlineGridWith50PercentWidth}
          />
        )}

        {!isFront && (
          <TextField
            source="data.bo.tagNo"
            label="resources.FasTagEntries.fields.data.tagNo"
            emptyText={MESSAGE_NA}
            className={classes.inlineGridWith50PercentWidth}
          />
        )}

        {!isFront && (
          <BooleanField
            source="data.bo.isKycDocVerified"
            label="resources.FasTagEntries.fields.data.kycDocVerified"
            emptyText={MESSAGE_NA}
            className={classes.inlineGridWith50PercentWidth}
          />
        )}

      </CommonMasterFields>

      {!isFront && (
        <>
          <SectionTitle
            label="resources.FasTagEntries.sections.attachments"
            style={{ marginTop: 20 }}
          />
          <DisplayMediaFields
            sourceFieldName="files1"
            viewFilesLabel="resources.FasTagEntries.fields.data.attachedDocument.panCard"
            acceptMultiple={true}
            showFileInput={false}
            viewFileName="files1"
            allowDelete={false}
            {...rest}
          />

          <DisplayMediaFields
            sourceFieldName="files2"
            viewFilesLabel="resources.FasTagEntries.fields.data.attachedDocument.aadharCard"
            acceptMultiple={true}
            showFileInput={false}
            viewFileName="files2"
            allowDelete={false}
            {...rest}
          />

          <DisplayMediaFields
            sourceFieldName="files3"
            viewFilesLabel="resources.FasTagEntries.fields.data.attachedDocument.rcCopy"
            acceptMultiple={true}
            showFileInput={false}
            viewFileName="files3"
            allowDelete={false}
            {...rest}
          />
        </>
      )}
    </SimpleShowLayout>
  );
};

export { FastTagShowFormDataComponent };
