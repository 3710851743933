import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  placeholder: {
    textAlign: "center",
    borderStyle: "dashed",
    borderWidth: "1px",
    borderRadius: "2%",
    color: "rgba(0, 0, 0, 0.54)"
  },
  note: {
    fontSize: "small",
    fontStyle: "italic"
  }
}));

const FileInputPlaceholder = ({
  fileDescription = "Documents",
  maxFileSize = "15 MBs"
}) => {
  const classes = useStyles();

  return (
    <Typography
      variant="body1"
      color="inherit"
      className={classes.placeholder}
      id="react-admin-title"
    >
      <p>Drag & Drop {fileDescription} to upload</p>
      <p>Or click to select one after the other</p>
      <p className={classes.note}>
        (Only valid {fileDescription.toLowerCase()} less than {maxFileSize} size
        will be accepted)
      </p>
    </Typography>
  );
};

export { FileInputPlaceholder };
