import { useState } from "react";
import set from "lodash/set";
import get from "lodash/get";

import {
  useTranslate,
  TextInput,
  SelectInput,
  useCreateController,
  useEditController,
  usePermissions,
  SimpleFormIterator,
  FormDataConsumer,
  ArrayInput,
  BooleanInput,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DisplayMediaFields } from "@bedrock/ra-core";
import { hasPermission } from "@bedrock/ra-core/dist/lib/helpers";
import {
  CommonMasterInput,
  SectionTitle,
  allowedFileTypes,
  PERM_MEDIA_DELETE,
  APP_MAX_FILE_SIZE_BYTES,
  vehicleTypeChoices,
  FileInputPlaceholder,
  ArrayAddButton,
  ArrayRemoveButton,
} from "../../../..";
import { validations } from "./validations";
import { defaultStyles } from "../../../../styles";
import { vehicleClassificationAndChargesCalculator } from "./vehicleCalculators";
import { VehicleFasTagInputDetailsTable } from "./VehicleFasTagInputDetailsTable";

const useStyles = makeStyles(defaultStyles);

const FastTagFormCreateEditContainer = ({ isFront, ...rest }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const controllerProps = isFront
    ? useCreateController({ ...rest })
    : useEditController({ ...rest });

  const [vehicleDetailsCount, setVehicleDetailsCount] = useState(0);

  const { permissions } = usePermissions();
  const allowFileDelete = hasPermission(permissions, PERM_MEDIA_DELETE);

  const calculateSum = (vehicleDetails, param) => {
    var sum = 0;
    vehicleDetails?.map((item) => {
      if (item && item[`${param}`]) {
        sum += item[`${param}`];
      }
    });
    return sum;
  };

  const calculateVehicleData = (formData, getSource, scopedFormData) => {
    const vehicles = formData?.data?.vehicles?.list;
    const vehicleType = scopedFormData?.vehicleType;

    if (!vehicles || !vehicles?.length) {
      return;
    }

    const calculatedCharges = vehicleClassificationAndChargesCalculator(
      vehicleType
    );

    setVehicleDetailsCount(vehicles?.length);
    if (calculatedCharges) {
      set(formData, getSource("issuanceFee"), calculatedCharges?.issuanceFee);
      set(
        formData,
        getSource("refundableSecurityDeposit"),
        calculatedCharges?.refundableSecurityDeposit
      );
      set(
        formData,
        getSource("firstRechargeAmount"),
        calculatedCharges?.firstRechargeAmount
      );
      set(formData, getSource("total"), calculatedCharges?.total);
    }
  };

  const calculateVehicleFeesData = (formData) => {
    const vehicles = formData?.data?.vehicles?.list;

    if (!vehicles || !vehicles?.length) {
      return;
    }

    set(
      formData,
      "data.vehicles.fees.issuanceFeeTotal",
      calculateSum(vehicles, "issuanceFee")
    );
    set(
      formData,
      "data.vehicles.fees.refundableSecurityDepositTotal",
      calculateSum(vehicles, "refundableSecurityDeposit")
    );
    set(
      formData,
      "data.vehicles.fees.firstRechargeAmountTotal",
      calculateSum(vehicles, "firstRechargeAmount")
    );
    set(
      formData,
      "data.vehicles.fees.subTotal",
      calculateSum(vehicles, "total")
    );
  };

  return (
    <>
      <Typography className={classes.heading}>
        {translate("resources.Common.forms.fasttag")}
      </Typography>

      <CommonMasterInput controllerProps={controllerProps} isFront={isFront} disabledResidentialAddressStatus={true}>
        {/* <SectionTitle label="resources.FasTagEntries.fields.data.vehicleDetails.label" /> */}
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, getSource, scopedFormData }) => {
            const vehicles = formData?.data?.vehicles?.list;
            calculateVehicleFeesData(formData);
            return (
              <>
                <ArrayInput
                  source="data.vehicles.list"
                  label="resources.FasTagEntries.fields.data.vehicleDetails.label"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: 500,
                    color: "unset"
                  }}
                  validate={validations.vehicleDetails}
                >
                  <SimpleFormIterator
                    disableAdd={vehicleDetailsCount < 5 ? false : true}
                    addButton={<ArrayAddButton
                      label="resources.FasTagEntries.fields.data.vehicleDetails.actions.add"
                    />}
                    removeButton={<ArrayRemoveButton
                      label="resources.FasTagEntries.fields.data.vehicleDetails.actions.remove"
                    />}
                  >
                    <FormDataConsumer subscription={{ values: true }}>
                      {({ formData, getSource, scopedFormData }) => {
                        calculateVehicleData(
                          formData,
                          getSource,
                          scopedFormData
                        );

                        return (
                          <SelectInput
                            source={getSource("vehicleType")}
                            choices={vehicleTypeChoices}
                            optionText="name"
                            optionValue="id"
                            validate={validations.vehicleType}
                            label="resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleType"
                            formClassName={classes.centeredFlex}
                          />
                        );
                      }}
                    </FormDataConsumer>

                    <TextInput
                      source="vehicleNumber"
                      validate={validations.vehicleNumber}
                      label="resources.FasTagEntries.fields.data.vehicleDetails.data.vehicleNumber"
                      formClassName={classes.centeredFlex}
                    />

                    <TextInput
                      source="engineNumber"
                      validate={validations.engineNumber}
                      label="resources.FasTagEntries.fields.data.vehicleDetails.data.engineNumber"
                      formClassName={classes.centeredFlex}
                    />

                    <TextInput
                      source="chassisNumber"
                      validate={validations.chassisNumber}
                      label="resources.FasTagEntries.fields.data.vehicleDetails.data.chassisNumber"
                      formClassName={classes.centeredFlex}
                    />
                  </SimpleFormIterator>
                </ArrayInput>

                {vehicles && (
                  <VehicleFasTagInputDetailsTable
                    vehicleDetails={vehicles}
                    vehicleFeeDetails={get(formData, "data.vehicles.fees", {})}
                    isShow={false}
                    {...rest}
                  />
                )}
              </>
            );
          }}
        </FormDataConsumer>

        {!isFront && (
          <SectionTitle label="resources.FasTagEntries.sections.administrative">
            <TextInput
              source="data.bo.totalAmountReceived"
              formClassName={classes.clearWithFloatLeft}
              // validate={validations.totalAmountReceived}
              label="resources.FasTagEntries.fields.data.totalAmountReceived"
            />

            <TextInput
              source="data.bo.cashCheckNumber"
              formClassName={classes.floatLeftWithMarginLeft}
              // validate={validations.cashCheck}
              label="resources.FasTagEntries.fields.data.cashCheck"
            />

            <TextInput
              source="data.bo.tagNo"
              formClassName={classes.floatLeftWithMarginLeft}
              label="resources.FasTagEntries.fields.data.tagNo"
            />
            <BooleanInput
              source="data.bo.isKycDocVerified"
              formClassName={classes.floatLeftWithMarginLeft}
              label="resources.FasTagEntries.fields.data.kycDocVerified"
            />
          </SectionTitle>
        )}
      </CommonMasterInput>

      <SectionTitle label="resources.FasTagEntries.sections.attachments" />
      <DisplayMediaFields
        sourceFieldName="files1"
        acceptFileType={allowedFileTypes}
        fileInputLabel="resources.FasTagEntries.fields.data.attachedDocument.panCard"
        viewFilesLabel="resources.FasTagEntries.fields.data.attachedDocument.uploadedPanCard"
        acceptMultiple={true}
        viewFileName="files1"
        allowDelete={allowFileDelete}
        maxSize={APP_MAX_FILE_SIZE_BYTES}
        placeholder={<FileInputPlaceholder />}
        {...rest}
      />

      <DisplayMediaFields
        sourceFieldName="files2"
        acceptFileType={allowedFileTypes}
        fileInputLabel="resources.FasTagEntries.fields.data.attachedDocument.aadharCard"
        viewFilesLabel="resources.FasTagEntries.fields.data.attachedDocument.uploadedAadharCard"
        acceptMultiple={true}
        viewFileName="files2"
        allowDelete={allowFileDelete}
        maxSize={APP_MAX_FILE_SIZE_BYTES}
        placeholder={<FileInputPlaceholder />}
        {...rest}
      />

      <DisplayMediaFields
        sourceFieldName="files3"
        acceptFileType={allowedFileTypes}
        fileInputLabel="resources.FasTagEntries.fields.data.attachedDocument.rcCopy"
        viewFilesLabel="resources.FasTagEntries.fields.data.attachedDocument.uploadedRcCopy"
        acceptMultiple={true}
        viewFileName="files3"
        allowDelete={allowFileDelete}
        maxSize={APP_MAX_FILE_SIZE_BYTES}
        placeholder={<FileInputPlaceholder />}
        {...rest}
      />
    </>
  );
};

export { FastTagFormCreateEditContainer };
