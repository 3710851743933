import { Toolbar } from "react-admin";

import { FrontFormSaveButton } from "./FrontFormSaveButton";
import { BackLinkButton } from "./BackLinkButton"

const FormEntryCreateToolbarButton = ({ formId, clearLocalFormState, ...rest }) => {
  return (
    <Toolbar {...rest} style={{ marginBottom: 30 }}>
      <FrontFormSaveButton formId={formId} clearLocalFormState={clearLocalFormState}/>
      <BackLinkButton to="/" label="components.app.buttons.back" />
    </Toolbar>
  );
};

export { FormEntryCreateToolbarButton };
